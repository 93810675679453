@import '../../colors.scss';

.frontpage {
  .content-wrapper {
    width: 100%;
    max-width: 1020px;
    margin-right: auto;
    margin-left: auto;
  }

  a {
    color: black;
  }

  .kitwrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 50px 0;

    .KitBox {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
      transition: 400ms;

      &:hover {
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.7);
      }
    }
  }

  .welcome .inset {
    padding: 20px 0 0 0;

    h3 {
      margin: 6px 32px 0 32px;
    }

    .KitBox {
      padding-bottom: 0;
      margin-bottom: 0;
    }

    .flicking-pagination {
      position: relative;
    }

    .flicking-pagination-bullet-active {
      background-color: $mainBrandColor;
  }
  }
}
