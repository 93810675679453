.wificonfig {
  margin-bottom: 56px;
}

#programbutton {
  height: 32px;
}

.codesnippethighlighting {
  b {
    background-color: #aaf;
  }
}

.overview-video {
  width: 334px;
  margin: 0 auto;

  video {
    height: 250px;
  }
}

.boxes {
  display: flex;
  justify-content: space-evenly;
  margin-top: 24px;
  margin-bottom: 24px;

  .inset {
    width: 400px;
    height: 100%;

    li {
      list-style-type: square;
    }
  }
}

.docs-section {
  margin-bottom: 48px;
}
