.settings-button {
  padding-top: 2px;
  border: none;
  // margin-left: 6px;
  background: none;
  color: #eee;
  transition: ease-in 0.25s;

  &:focus {
    outline: none;
  }

  &.checked {
    color: #888;
  }

  &:hover {
    color: #333;
  }
}
