.free-rtos {
  .slider {
    display: flex;

    .label {
      width: 280px;
    }

    .SimpleSlider {
      flex-grow: 2;
    }
  }

  .slider-description {
    margin-bottom: 18px;
    font-size: small;
  }
}
