.footer {
  padding: 40px 0 48px 0;
  background-color: #596171;

  .content-wrapper {
    display: flex;
    justify-content: flex-start;

    .footer-links {
      // display: flex;
      margin-right: 50px;
      color: rgba(255, 255, 255, 0.87);

      li {
        margin-bottom: 8px;
      }

      a {
        margin: 0;
        margin-bottom: 8px;
        color: rgba(255, 255, 255, 0.87);
        font-size: 12px;
        font-weight: 300;
        text-decoration: none;
      }

      ul {
        // width: 145px;
        padding: 0;
        margin: 0;
        list-style-type: none;
      }

      h3.header {
        max-width: 184px;
        height: 32px;
        margin: 0;
        margin-bottom: 16px;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.3em;
        text-transform: uppercase;
      }
    }

    .footer-logo {
      display: flex;
      flex-wrap: wrap;
      align-content: space-between;
      justify-content: flex-end;
      margin-right: 5px;
      margin-left: auto;
      text-align: right;

      img {
        width: 132px;
      }
    }
  }
}
