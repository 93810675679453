$background: #f6f6f6;

.CodeView {
  position: relative;
  overflow: auto;
  padding: 6px;
  margin: 6px 0;
  background: $background;

  &.border {
    border: 1px solid #333;
  }

  pre.lowlight {
    width: max-content;
    min-width: 100%;
    margin: 0;

    .hljs {
      color: #2f3337;

      .hljs-marker {
        position: relative;
        padding: 0 6px;
        // border: 1px dashed rgb(103, 117, 139);
        margin: 0 -6px;
        background: #dee0ee;
      }

      .hljs-comment {
        color: #656e77;
      }

      .hljs-keyword,
      .hljs-selector-tag,
      .hljs-meta-keyword,
      .hljs-doctag,
      .hljs-section,
      .hljs-selector-class,
      .hljs-meta,
      .hljs-selector-pseudo,
      .hljs-attr {
        color: #015692;
      }

      .hljs-attribute {
        color: #803378;
      }

      .hljs-name,
      .hljs-type,
      .hljs-number,
      .hljs-selector-id,
      .hljs-quote,
      .hljs-template-tag,
      .hljs-built_in,
      .hljs-title,
      .hljs-literal {
        color: #b75501;
      }

      .hljs-string,
      .hljs-regexp,
      .hljs-symbol,
      .hljs-variable,
      .hljs-template-variable,
      .hljs-link,
      .hljs-selector-attr,
      .hljs-meta-string {
        color: #54790d;
      }

      .hljs-bullet,
      .hljs-code {
        color: #535a60;
      }

      .hljs-deletion {
        color: #c02d2e;
      }

      .hljs-addition {
        color: #2f6f44;
      }

      .hljs-emphasis {
        font-style: italic;
      }

      .hljs-strong {
        font-weight: bold;
      }
    }
  }
}
