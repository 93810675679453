.topnav {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 8px 0;

  img.topnav-img {
    margin-left: -8px;
  }

  img.cloud-img {
    height: 32px;
    margin-top: 12px;
    margin-right: 20px;
  }
}
