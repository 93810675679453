.more {
    margin: 60px 0;

    .more-extra {
        display: flex;
        margin: 20px 0;

        > div:first-child {
            margin-right: 20px;
        }

        .tile {
            // flex-grow: 1;
            flex-basis: 50%;
            margin-top: 20px;
        }

        .btn {
            width: 190px;
            margin: 5px;
        }

        .grid {
            display: grid;
            gap: 25px 10px;
            grid-template-columns: 50px auto;
            
            div.img {
                align-self: center;
            }

            div.text {
                grid-column-start: 2;

                p {
                    margin: 0 0 6px 0;
                }

                a {
                    margin-top: 6px;
                }
            }
        }

        img {
            width: 32px;
            // height: 32px;
            // margin-top: 6px;
        }
    }
}