.rc-collapse.collapse-simple {
  border: none;
  margin-top: 6px;
  background-color: transparent;
  color: white;

  .rc-collapse-item > .rc-collapse-header {
    padding: 0;
    outline: none;
  }

  .rc-collapse-content-box {
    margin-top: 0;
  }

  div > .rc-collapse-content {
    padding: 10px 0;
    background-color: transparent;
    color: black;
  }

  &.white > div {
    > .rc-collapse-header {
      color: rgba(255, 255, 255, 0.87);
      font-size: inherit;
    }

    > .rc-collapse-content {
      background-color: transparent;
      color: rgba(255, 255, 255, 0.87);
      font-size: 14px;
    }
  }

  &.red > div > .rc-collapse-header {
    background-color: transparent;
    color: red;
  }

  div > .rc-collapse-header:hover i {
    $hoverColor: #faeded;

    background-color: $hoverColor;
    border-radius: 50%;
    -webkit-box-shadow: 0 0 10px 2px $hoverColor;
    -moz-box-shadow: 0 0 10px 2px $hoverColor;
    box-shadow: 0 0 15px 5px $hoverColor;
    cursor: pointer;
  }
}
