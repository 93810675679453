@import "colors.scss";

.landingpage {
  h2 {
    font-size: 30px;
    font-weight: bold;
    line-height: 41px;
  }

  h3 {
    font-size: 20px;
    font-weight: bold;
  }

  h4 {
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
  }

  h5 {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
  }

  a {
    color: $linkColor;
    text-decoration: none;
  }

  .btn {
    display: inline-block;
    box-sizing: border-box;
    padding: 8px 28px;
    border: none;
    border-radius: 2px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: normal;
    line-height: 16px;
    text-align: center;
    text-decoration: none;

    &:hover {
      cursor: pointer;
      text-decoration: none;
      transform: scale(1.02);
      -webkit-transition-duration: 0.25s;
      -moz-transition-duration: 0.25s;
      -o-transition-duration: 0.25s;
      transition-duration: 0.25s;
    }

    &.red {
      background-color: $mainBrandColor;
      color: #fff;

      > a,
      > a:link,
      > a:hover,
      > a:active,
      > a:visited {
        color: #fff;
      }
    }

    &.green-big {
      background-color: #5ebf33;
      color: #fff;

      transform: scale(2);

      animation: glow 1300ms infinite;

      > a,
      > a:link,
      > a:hover,
      > a:active,
      > a:visited {
        color: #fff;
      }
    }

    @keyframes glow {
      0% {
        box-shadow: 0 0 3px #000000;
      }
      50% {
        box-shadow: 0 0 10px #000000;
      }
      100% {
        box-shadow: 0 0 3px #000000;
      }
    }

    &.red-inverted {
      background-color: white;
      color: $mainBrandColor;
      border: 1px solid $mainBrandColor;

      > a,
      > a:link,
      > a:hover,
      > a:active,
      > a:visited {
        color: $mainBrandColor;
      }
    }

    &.gray {
      background-color: #7a7a7a;
      color: #fff;
    }

    &.right {
      width: 120px;
      margin-right: 0;
      margin-left: auto;
    }

    &.center {
      display: block;
      width: 120px;
      margin-right: auto;
      margin-left: auto;
    }
  }

  button.as-link {
    border: none;
    background: none;
    cursor: pointer;
  }

  .content-wrapper {
    width: 100%;
    max-width: 950px;
    margin-right: auto;
    margin-left: auto;
  }

  .arrow-toggle {
    cursor: pointer;

    img {
      width: 16px;
      height: 16px;
      margin-right: 8px;
      line-height: 16px;
      text-align: center;
      transition: 0.25s ease;
      transition: transform 0.5s ease;
    }

    .rotate {
      transform: rotate(-180deg);
    }

    h5:hover img {
      border-radius: 50%;
      -webkit-box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.6);
      -moz-box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.6);
      box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.6);
      transition: 0.25s ease;
    }
  }

  ol.blue-bullets {
    padding-left: 40px;
    counter-reset: inset-counter;
    list-style: none;

    li {
      position: relative;
      align-items: center;
      margin: 0 0 0.5rem 0;
      margin-bottom: 16px;
      counter-increment: inset-counter;
      transition: 2s ease;

      $bullet-size: 24px;

      ::before {
        position: absolute;
        top: 0;
        left: -$bullet-size - 10px;
        width: $bullet-size;
        height: $bullet-size;
        background: #4285f4;
        border-radius: 50%;
        color: #fff;
        content: counter(inset-counter);
        font-weight: bold;
        line-height: $bullet-size;
        text-align: center;
      }
    }
  }

  .alert {
    // width: 100%;
    padding: 12px;
    background-color: $mainBrandColor;
    color: #fff;
    font-size: 14px;
    text-align: center;
  }

  .alert-text {
    margin-top: 0;
    margin-bottom: 0;
  }

  .mobile-warning {
    display: none;
    overflow: hidden;
    width: 0;
    height: 0;
    opacity: 0;
  }

  @media screen and (max-width: 949px) {
    .mobile-warning {
      display: block;
      width: 100%;
      max-width: 464px;
      height: auto;
      margin-bottom: 20px;
      opacity: 1;
    }
  }

  .inset {
    position: relative;
    box-sizing: border-box;
    flex-grow: 0;
    padding: 20px 32px 33px 33px;
    border: 1px solid $mainBrandColor;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
  }

  .tile {
    position: relative;
    // width: 455px;
    box-sizing: border-box;
    padding: 24px 32px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.5);
  }
}
