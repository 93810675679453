.Provision {
  margin-bottom: 60px;

  .select-cloud-provider {
    display: flex;
    margin-bottom: 30px;

    .cloud-provider {
      display: flex;
      width: 200px;
      height: 100px;
      align-items: center;
      justify-content: center;
      border: 1px solid gray;
      margin-right: 8px;
      background: white;
      border-radius: 10px;

      &:focus {
        outline: none;
      }

      &.selected {
        border: 2px solid darkslategray;
      }

      img.aws {
        width: 50px;
      }

      img.google {
        width: 140px;
      }

      img.azure {
        width: 28px;
        margin-right: 6px;
      }
    }

    button span {
      font-weight: 500;
      font-size: 16px;
    }
  }
}
