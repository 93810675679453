.SliderControl {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  div.label,
  input.label {
    min-height: unset;
    margin: 0;
    // background-color: yellow;
  }

  .ConfigSlider {
    flex-grow: 2;

    .config {
      display: flex;
      justify-content: space-between;
      margin-bottom: 18px;

      .min-val,
      .step-val,
      .max-val {
        width: 60px;
      }
    }
  }

  .input-slider {
    width: 100%;
    height: 25px;
    border: 1px solid rgb(180, 180, 180);
    -webkit-appearance: none;
    border-radius: 13px;
    opacity: 0.7;
    outline: none;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;

    &::-webkit-slider-thumb {
      width: 25px;
      height: 25px;
      border: 1px solid rgb(128, 128, 128);
      -webkit-appearance: none;
      background-color: rgb(250, 250, 250);
      border-radius: 50%;
      cursor: pointer;
    }

    &::-moz-range-thumb {
      width: 25px;
      height: 25px;
      border: 1px solid rgb(128, 128, 128);
      appearance: none;
      background-color: rgb(250, 250, 250);
      border-radius: 50%;
      cursor: pointer;
    }

    &:hover {
      opacity: 1; /* Fully shown on mouse-over */

      &::-webkit-slider-thumb {
        background-color: #2196f3;
      }

      &::-moz-range-thumb {
        background-color: #2196f3;
      }
    }
  }

  div.label {
    margin-right: 12px;
  }

  input.label {
    margin-right: 8px;
  }

  .input-value {
    width: 60px;
    margin-left: 6px;
  }

  &:hover {
    div.label {
      background: rgba(220, 220, 220, 0.4);
    }

    .delete-button {
      color: #d72e43;
    }
  }
}
