.cellular-mini {
  section {
    margin: 36px 0;
  }

  .column {
    float: left;
  }

  .column-left {
    width: 70%;
  }

  .column-right {
    width: 30%;
  }

  .column img {
    max-width: 100%;
    max-height: 100%;
  }

  /* Clear floats after the columns */
  .row::after {
    display: table;
    clear: both;
    content: "";
  }

  .step-list li {
    margin: 15px 0;
  }

  button.lightbulb {
    width: 160px;
    height: 100px;
    border: 2px solid rgb(62, 62, 80);
    background-color: transparent;
    border-radius: 5px;
  }

  .lightbulb {
    width: 40px;
    height: 40px;
  }

  // .lightbulb:hover {
  //   box-shadow: 0 0 100px 0 rgb(0, 0, 0);
  // }

  svg.header-icon {
    color: rgb(69, 69, 112);
  }

  .stream-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
  }
}
