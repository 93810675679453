.ChooseBoard {
  margin-bottom: 56px;

  .boards {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    margin-top: 56px;

    .board {
      text-align: center;

      img.curiosity {
        width: 350px;
      }

      img.wfi32 {
        width: 210px;
      }
    }
  }
}
