@import "../../colors.scss";

.ProgressBar {
	position: relative;
	margin-top: 30px;

	img,
	svg {
		position: absolute;
	}

	.progressbar {
		position: absolute;
		width: 100%;
		box-sizing: border-box;
		padding: 34px 34px;

		div.white {
			height: 8px;
			background-color: white;
		}

		div.aws {
			background-color: $awsBrandColor;
		}

		div.gcp {
			background-color: $gcpBrandColor;
		}

		div.wifi {
			width: 33%;
			height: 100%;
			animation: toWifi 2s;
		}

		@keyframes toWifi {
			0% {
				width: 0;
			}
			100% {
				width: 33%;
			}
		}

		div.cloud {
			width: 66%;
			height: 100%;
			animation: toCloud 2s;
		}

		@keyframes toCloud {
			0% {
				width: 33%;
			}
			100% {
				width: 66%;
			}
		}

		div.streaming {
			width: 100%;
			height: 100%;
			animation: toStreaming 2s;
		}

		@keyframes toStreaming {
			0% {
				width: 66%;
			}
			100% {
				width: 100%;
			}
		}
	}

	.symbols {
		// position: absolute;
		display: flex;
		width: 100%;
		justify-content: space-between;
	}

	.symbol {
		position: relative;
		display: inline-block;
		width: 76px;
		height: 76px;
	}

	.checkmark {
		left: 50px;
	}

	.Pulsar {
		animation: ping 2s ease-in-out both;
		animation-iteration-count: infinite;
	}

	.gray {
		stroke: gray;
	}

	.aws {
		stroke: $awsBrandColor;
	}

	.gcp {
		stroke: $gcpBrandColor;
	}
}

@keyframes ping {
	0% {
		opacity: 0;
		-webkit-transform: scale(0.8);
		transform: scale(0.8);
	}

	50% {
		opacity: 0.8;
		-webkit-transform: scale(1);
		transform: scale(1);
	}

	100% {
		opacity: 0;
		-webkit-transform: scale(0.8);
		transform: scale(0.8);
	}
}
