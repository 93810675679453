.wificonfig {
  .wificonfig-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

#setup-video {
  height: 100%;
  max-height: 320px;
}

.wireless-config-connected-video {
  width: 200px;
  margin-right: auto;
  margin-left: auto;
}

#connectedVideo {
  width: 100%;
}

.wifi-connected-buttons {
  text-align: right;

  button {
    margin-left: 16px;
  }
}
