.landingpage.PicIot .WhatsNext {
    .btn.center {
        width: 160px;
    }

    .control-your-device {
        margin-top: 32px;

        ul {
            list-style: square;
        }
    }

    .delete-button {
        visibility: collapse;
    }
}
