.overview {
  padding: 30px 0 60px 0;

  &--monitor {
    @extend.overview;
    padding: 0px 0 60px 0;
  }

  &--no-bot-padding {
    padding: 30px 0 0 0;
  }

  .content {
    display: flex;
    align-items: center;
    margin-top: 48px;

    h3 {
      margin-top: 0;
    }

    .left {
      flex: 2;
      margin-right: 50px;
    }

    .provision {
      min-width: 330px;
      flex: 1;
    }

    .overview-image {
      img {
        height: 150px;
      }
    }
  }

  .overview-video {
    width: 334px;
    margin: 0 auto;

    video {
      height: 250px;
    }
  }
}
