.SimpleSlider {
  // width: 100%;
  height: 25px;
  border: 1px solid rgb(180, 180, 180);
  -webkit-appearance: none;
  border-radius: 13px;
  opacity: 0.7;
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;

  &::-webkit-slider-thumb {
    width: 25px;
    height: 25px;
    border: 1px solid rgb(128, 128, 128);
    -webkit-appearance: none;
    background-color: rgb(250, 250, 250);
    border-radius: 50%;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border: 1px solid rgb(128, 128, 128);
    appearance: none;
    background-color: rgb(250, 250, 250);
    border-radius: 50%;
    cursor: pointer;
  }

  &:hover {
    opacity: 1; /* Fully shown on mouse-over */

    &::-webkit-slider-thumb {
      background-color: #2196f3;
    }

    &::-moz-range-thumb {
      background-color: #2196f3;
    }
  }
}
