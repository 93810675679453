.welcome {
  padding: 60px 0;
  background-color: #102437;
  background-image: url(../../images/secure-iot-background.png);
  background-position: center;
  background-size: cover;
  font-family: "Open Sans", sans-serif;

  .content {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;

    .left {
      width: 472px;
      color: rgba($color: white, $alpha: 0.87);
      line-height: 1.67;

      h2 {
        margin-top: 0;
      }
    }

    .center {
      color: rgba($color: white, $alpha: 0.87);
      line-height: 1.67;

      h2 {
        margin-top: 0;
      }
    }

    .inset {
      width: 384px;
    }

    .welcome-info-text {
      font-size: 14px;
      font-weight: 200;
    }
  }
}
