.wifisetuptile {
  width: 455px;

  .mui-textfield {
    position: relative;
    display: block;
    padding-top: 15px;
    margin-bottom: 20px;

    >input,
    >textarea {
      display: block;
      width: 100%;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      padding: 0;
      border: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.26);
      background-color: transparent;
      background-image: none;
      border-radius: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
      color: rgba(0, 0, 0, 0.87);
      font-family: inherit;
      font-size: 16px;
      line-height: inherit;
      outline: 0;

      &:focus {
        border-width: 2px;
        border-color: #2196f3;
      }
    }
  }

  fieldset.wifi-form {
    padding: 0;
    border: none;
    margin: 0;
    margin-top: 20px;

    legend {
      margin-bottom: 16px;
    }

    label {
      display: block;
      padding: 8px 10px;
      border: 1px solid rgba(0, 0, 0, 0.12);
      margin-bottom: 8px;
      border-radius: 2px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);

      &.checked {
        border: 1px solid #4c82fc;
        box-shadow: none;
      }
    }
  }

  .hidden {
    display: none;
    overflow: hidden;
    width: 0;
    height: 0;
    opacity: 0;
  }

  .toggle-pw-vis {
    text-align: right;
  }

  #toggle-pw-vis {
    right: 25px;
    border: none;
    background: transparent;
    color: #4285f4;
    cursor: pointer;
    font-size: 13px;
    font-weight: 300;

    &:focus {
      outline: none;
    }
  }

  .wifi-form-buttons {
    margin-top: 16px;
    text-align: right;
  }
}
