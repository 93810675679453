.toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;

  div.label {
    min-height: unset;
    // background-color: yellow;
  }

  &:hover {
    div.label {
      background: rgba(220, 220, 220, 0.4);
    }

    .delete-button {
      color: #d72e43;
    }
  }

  label {
    $togglewidth: 50px;
    $toggleheight: 20px;
    $toggleknobwidth: 24px;

    width: $togglewidth;
    height: $toggleheight;
    box-sizing: border-box;
    border: 1px solid rgb(180, 180, 180);
    // margin-right: 0;
    // margin-left: auto;
    margin: auto 0 auto auto;
    background-color: rgb(250, 250, 250);
    border-radius: $toggleheight / 2;
    cursor: pointer;

    span {
      display: inline-block;
      width: $toggleknobwidth;
      height: $toggleheight - 2;
      box-sizing: border-box;
      border: 1px solid rgb(128, 128, 128);
      background-color: rgb(250, 250, 250);
      border-radius: ($toggleheight - 2) / 2;
      -webkit-box-shadow: 2px 1px 2px 0 #aaa;
      -moz-box-shadow: 2px 1px 2px 0 #aaa;
      box-shadow: 2px 1px 2px 0 #aaa;
      transition: margin-left 0.2s ease-in-out;
    }

    input {
      position: absolute;
      width: 0;
      height: 0;
      cursor: pointer;
      opacity: 0;
    }

    input:checked ~ span {
      border: none;
      margin-left: $togglewidth - $toggleknobwidth - 2;
      background-color: #2196f3;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      transition: margin-left 0.2s ease-in-out;
    }
  }
}
