.delete-button {
  padding-top: 5px;
  border: none;
  margin-left: 6px;
  background: none;
  color: #eee;
  transition: ease-in 0.25s;

  &:hover {
    color: #d72e43;
  }
}
