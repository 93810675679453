.TextBox {
  height: 21px;
  padding: 2px 16px;
  border: 1px solid #c3cbd3;
  background: transparent;
  border-radius: 2px;

  &:hover {
    border: 1px solid rgba(66, 133, 244, 0.5);
    -webkit-box-shadow: 0 0 4px 1px rgba(66, 133, 244, 0.5);
    -moz-box-shadow: 0 0 4px 1px rgba(66, 133, 244, 0.5);
    box-shadow: 0 0 4px 1px rgba(66, 133, 244, 0.5);
  }

  &:focus {
    border: 1px solid #4285f4;
    outline: none;
  }

  // &.input-value {
  //   margin-left: 15px;
  // }
}
