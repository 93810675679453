@import '../../colors.scss';

.KitBox {
  width: 300px;
  height: 350px;
  padding: 12px;
  margin: 8px;

  a {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;

    .kitimage {
      display: flex;
      height: 210px;
      align-items: center;
      justify-content: center;

      img {
        display: block;
        max-width: 300px;
        max-height: 200px;
        margin: auto;
      }
    }

    .description {
      h4 {
        margin: 0;
      }

      hr {
        border: 0;
        border-top: 1px solid #ddd;
      }

      .properties {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .connection {
          height: 20px;
          padding: 0 8px;
          margin: 0 6px;
          border-radius: 18px;
          color: white;
        }

        .red {
          background-color: $mainBrandColor;
        }

        .blue {
          background-color: #333fb1;
        }

        .gcp {
          width: auto;
          height: 20px;
          padding: 6px;
          margin: 0 6px;
        }

        .aws {
          width: auto;
          height: 18px;
          padding: 6px;
          margin: 0 6px;
        }

        .azure {
          width: auto;
          height: 18px;
          padding: 6px;
          margin: 0 6px;
        }
      }
    }
  }
}
