.visualizer {
  .info-section {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    padding: 64px;

    .info-section-header {
      width: 950px;
      height: 24px;
      margin: 0 auto 24px auto;
      color: rgba(0, 0, 0, 0.87);
      font-family: "Open Sans", sans-serif;
      font-size: 20px;
      font-weight: bold;
      line-height: 24px;
    }
  }

  .widgets {
    padding: 24px 0 42px 0;
    background-color: rgba(216, 216, 216, 0.2);

    .widgets-wrapper {
      display: flex;
      width: 100%;
      max-width: 950px;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 30px;
      margin-right: auto;
      margin-left: auto;
    }
  }

  .chart {
    position: relative;
    box-sizing: border-box;
    padding: 0 20px 50px 20px;
    margin-bottom: 24px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);

    h4.title {
      margin-bottom: 0;
    }
  }
}
