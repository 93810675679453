.multicontrolpane {
  padding: 0 6px 18px 18px;
  border: 1px solid #aaa;
  margin: 4px;
  box-shadow: 2px 2px 6px #aaa;

  h4 {
    margin-bottom: 6px;
  }

  .btn.round {
    width: 20px;
    height: 20px;
    padding: 0;
    border: 1px solid #eee;
    background: none;
    border-radius: 10px;
    box-shadow: none;
    transition: ease-in 0.25s;

    &:focus {
      outline: none;
    }

    &:hover {
      background-color: #ddd;
      border-radius: 10px;
      outline: none;
    }
  }

  .submit {
    margin-top: 24px;
  }
}
