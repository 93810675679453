.arduino {
  .slider {
    display: flex;
    margin-bottom: 12px;

    .label {
      width: 250px;
    }

    .SimpleSlider {
      flex-grow: 2;
    }
  }
}
