@import "../../colors.scss";

.ProgressBarMini {
  position: relative;
  margin-top: 30px;

  img,
  svg {
    position: absolute;
  }

  .progressbar-mini {
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    padding: 34px 34px;

    div.white {
      height: 8px;
      background-color: white;
    }

    div.aws {
      background-color: $awsBrandColor;
    }

    div.gcp {
      background-color: $gcpBrandColor;
    }

    div.cloud {
      width: 33%;
      height: 100%;
      animation: toCloud 2s;
    }

    @keyframes toCloud {
      0% {
        width: 0%;
      }
      100% {
        width: 33%;
      }
    }

    div.streaming {
      width: 66%;
      height: 100%;
      animation: toStreaming 2s;
    }

    @keyframes toStreaming {
      0% {
        width: 33%;
      }
      100% {
        width: 66%;
      }
    }

    div.docs {
      width: 100%;
      height: 100%;
      animation: toDocs 2s;
    }

    @keyframes toDocs {
      0% {
        width: 66%;
      }
      100% {
        width: 100%;
      }
    }
  }

  .symbols {
    // position: absolute;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .symbol {
    position: relative;
    display: inline-block;
    width: 76px;
    height: 76px;
  }

  .checkmark {
    left: 50px;
  }

  .Pulsar {
    animation: ping 2s ease-in-out both;
    animation-iteration-count: infinite;
  }

  .gray {
    stroke: gray;
  }

  .aws {
    stroke: $awsBrandColor;
  }

  .gcp {
    stroke: $gcpBrandColor;
  }

  .green {
    stroke: #5ebf33;
  }
}
