.rc-collapse.rounded {
  border: none;
  margin-top: 30px;

  :focus {
    outline: none;
  }

  > .rc-collapse-item.rounded {
    box-sizing: border-box;
    padding: 20px;
    border: 1px solid #acb7c3;
    margin: 6px 0;
    background-color: white;
    border-radius: 8px;
    box-shadow: none;

    .rounded-header {
      color: black;
      font-size: large;
      font-weight: 500;

      &:hover i {
        $hoverColor: #f2f6f8;

        background-color: $hoverColor;
        border-radius: 50%;
        -webkit-box-shadow: 0 0 10px 2px $hoverColor;
        -moz-box-shadow: 0 0 10px 2px $hoverColor;
        box-shadow: 0 0 15px 5px $hoverColor;
        cursor: pointer;
      }
    }
  }

  div > .rc-collapse-content {
    color: black;
  }
}

.rc-collapse.slim {
  border: none;
  margin-top: 30px;

  :focus {
    outline: none;
  }

  > .rc-collapse-item.slim {
    box-sizing: border-box;
    padding: 6px;
    border: 1px solid #acb7c3;
    margin: 6px 0;
    background-color: white;
    box-shadow: none;

    .slim-header {
      color: black;
      font-size: large;
      font-weight: 500;

      &:hover i {
        $hoverColor: #f2f6f8;

        background-color: $hoverColor;
        border-radius: 50%;
        -webkit-box-shadow: 0 0 10px 2px $hoverColor;
        -moz-box-shadow: 0 0 10px 2px $hoverColor;
        box-shadow: 0 0 15px 5px $hoverColor;
        cursor: pointer;
      }
    }
  }

  div > .rc-collapse-content {
    color: black;
  }
}