.TextControl {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  // background-color: yellow;

  div.label,
  input.label {
    min-height: unset;
    margin: 0;
    // background-color: yellow;
  }

  div.label {
    margin-right: 12px;
  }

  input.label {
    margin-right: 8px;
  }

  &:hover {
    div.label {
      background-color: #eee;
    }

    .delete-button {
      color: #d72e43;
    }
  }

  .textvalue {
    flex-grow: 2;
  }
}
